import { createRouter,createWebHashHistory,Router } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router' 

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('../views/MainView.vue'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                component: () => import('../views/HomeView.vue'),
            },
            {
                path: '/contact-us',
                component: () => import('../views/ContactUsView.vue')
            },
            {
                path: '/about-us',
                component: () => import('../views/AboutUsView.vue')
            },
            {
                path: '/products',
                component: () => import('../views/ProductsView.vue')
            }
        ]
    }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
