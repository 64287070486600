import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

// 导入 bootstrap 图标库
import 'bootstrap-icons/font/bootstrap-icons.min.css'

const app = createApp(App)

app.use(router)
app.mount('#app')

axios.defaults.baseURL = 'https://www.china-fix.com/sansong/api/'
app.provide('$axios',axios)



